import bookSVG from '../icons/icon_chaseLinkBook.svg';
import computerSVG from '../icons/icon_chaseLinkComputer.svg';
import leafSVG from '../icons/icon_chaseLinkLeaf.svg';
import gearSVG from '../icons/icon_chaseLinkGears.svg';
import { getAEMBackendUrls } from '../utils/Utils';
export const BRAND_LAND_ROVER = 'LandRover';
export const BRAND = 'Fisker';
export const onlineProfileIdentifier = "54297629";
export const logLabel = {
    error: "ERROR",
    debug: "DEBUG",
    info: "INFO"
};
export const beforeMyAccCall = "Invoking my account api call";
export const afterMyAccCall = "Successfully retrieved account's data";
export const logErrCode = {
    reactErr: "React_Exception",
    aemErr: "AEM_Exception"
};
export const logErrDetails = {
    reactErr: "UI exception occured : ",
    aemErr: "Exception occurred while connecting to AEM : ",
    myAcc: "Exception occurred while connecting to My Account Experience API : ",
    logger: "Exception occurred while connecting to Logger API : ",
};
export const API_URLS = {
    MyAccount: "/v1/myaccount",
    Logger: "/v1/logging",
    Header: '/v1/headers',
    TurnIn: '/v1/turnin'
};
export const AEM_CONTENT_URLS = {
    PROD: {
        BACKEND_URL: 'https://static.chase.com',
        IMAGES_BASE_URL_QA2: 'https://static.chasecdn.com',
    },
    NON_PROD: {
        BACKEND_URL: 'https://ccbcontent-qa2.chase.com',
        IMAGES_BASE_URL_QA2: 'https://static2-qa2.chasecdn.com',
    }
};
export const PDF = 'content-link';
export const FAQ_LINK = '/faq';
export const CONTACT_LINK = '/contact-us';
export const LEASE_COLLAPSE_TYPE = 'collapse';
export const LEASE_RETURN_LAND_ROVER_TITLE = 'return your land rover';
export const LEASE_RETURN_DATA = {
    dealerLocationLink: 'https://www.landroverusa.com/national-dealer-locator.html',
};
export const ALERT_TEXTS = {
    AlertHeading: " Your payment's past due",
    AlertText: ` Please make a payment as soon as you can so you don't fall
  behind on your account. If you've already made your payment,
  you're all set.`,
};
export const TIMED_MODAL = 'Timed Modal';
export const UNTIMED_MODAL = 'Untimed Modal';
export const RETAILER_STORE = `YOUR LAND ROVER RETAILER`;
export const ACCOUNTS_CHASELINKS_ARRAY_1 = [
    {
        URL: 'https://lrfg-uat.chase.com/_nuxt/icon-manage-autoplay.ke3yH-aX.svg',
        text: 'Manage automatic payments ',
        link: 'News',
    },
    { URL: computerSVG, text: 'Review pay-from accounts', link: 'News' },
    { URL: bookSVG, text: 'Manage paperless ', link: 'News' },
];
export const ACCOUNTS_CHASELINKS_ARRAY_2 = [
    {
        URL: leafSVG,
        text: 'Manage automatic payments ',
        link: 'News',
    },
    { URL: computerSVG, text: 'Send a secure message', link: 'News' },
    { URL: gearSVG, text: 'Manage paperless ', link: 'News' },
];
export const ACCOUNTS_DATA = {
    exploreMoreHeading: ' EXPLORE MORE WITH LAND ROVER',
    toolsHeading: 'YOUR AUTO TOOLS',
    manageHeading: 'MANAGE YOUR ACCOUNT AT CHASE.COM',
    elseHeading: 'LOOKING FOR SOMETHING ELSE? VISIT',
    chaseHeading: 'CHASE.COM',
    lastPaymentAmount: {
        heading: 'LAST PAYMENT AMOUNT',
        value: '$310.00',
    },
    lastPaymentDate: {
        heading: 'LAST PAYMENT DATE',
        value: 'JUN 2, 2021',
    },
    mailingAddress: {
        heading: 'ACCOUNT MAILING ADDRESS',
        row1: '1317 TILLAMACK ST',
        row2: 'LOAN MID-1 CYCLE',
        row3: ' BILLINGS, MT 59102-1022',
    },
    vinDetails: {
        heading: 'VIN',
        hiddenValue: '*********919584',
        actualValue: 'SALCP2FX3PH919584',
    },
    accDetails: {
        heading: 'Account Number',
        hiddenValue: '*****9325511',
        actualValue: '12117209325511',
    },
    loanTerm: {
        heading: 'Loan Term',
        popoverText: `This is your original loan term. If you've requested
    to defer any payments during your loan, your current
    loan term may be different than what is shown here.`,
    },
    organisationDate: {
        heading: 'Origination date',
        value: 'May 15, 2022',
    },
    accountDetailsHeading: 'land rover account details',
    accountProgress: {
        heading: 'COMPLETED PAYMENTS',
        value: '11 / 60',
        popOverText: `  This is your original loan term. If you’ve requested to
  defer any payments during your loan, your completed and
  total payments required to meet the terms of your agreement
  may be different than what is shown here.`,
    },
    remainingBalance: {
        heading: 'REMAINING BALANCE',
        value: '$14,414.71',
        popoverText: ` Your remaining balance is not the same as your payoff
  amount. Go to chase.com to get your payoff quote`,
    },
    intrestRate: { heading: 'INTEREST RATE', value: '2.74%' },
    mainCarImg: 'https://asset.chase.com/content/dam/unified-assets/photography/partner/fisker/FiskerMyaccountsImage.webp',
    maiHeading: `CONGRATULATIONS ON YOUR LAND ROVER'S FIRST ANNIVERSARY!`,
    mainCarYear: '2023 Land Rover',
    mainCarName: 'Discovery Sport',
    paymentDetailsHeading: 'SEE PAYMENT DETAILS',
    paymentDetailsData: [
        {
            heading: 'Current payment due',
            value: '$301.25',
        },
        {
            heading: 'Amount past due',
            value: '$301.25',
        },
        {
            heading: 'Late charges',
            value: '$30.00',
        },
        {
            heading: 'Other fees',
            value: '$01.00',
        },
    ],
    paymentInfoFinalAmount: {
        heading: 'Total amount due',
        value: '$632.50',
    },
    paymentInfoMonthlyPayment: {
        heading: 'Monthly payment',
        value: '$301.25',
    },
    accountDetailsLink: 'Account Details',
    amountsDue: { heading: 'Amount due', value: '$632.50' },
    dueDate: { heading: 'Due Date', value: 'Nov 13, 2023' },
    editButton: 'EDIT',
};
export const CAR_COLOUR_SELECT = ['red', 'green', 'grey'];
//AEM Configs
export const AEM_CONFIG = {
    ...getAEMBackendUrls(),
    VERSION: 'latest',
    FALLBACK_LAN: 'en',
};
export const CAROUSELTYPE = 'carousel';
export const AEM_MODELS = {
    LINKLIST: 'linkList',
    ICONLIST: 'iconList',
    CONTENTLIST: 'contentList',
    CAROUSEL: 'carousel',
    DIALOG: 'dialog',
    TILE: 'tile',
    STRUCTUREDCONTENTLIST: 'structuredContentList',
    LEGALCONTAINER: 'legalContainer',
    HEADER: 'header',
};
export const RETURN_OBJ = {
    returnObjects: true,
};
export const LINK_TYPES = {
    ANQUER: 'anquer',
    SUP: 'sup',
    BOLD: 'bold',
};
export const AEM_NAME_SPACES = {
    DEFAULT: 'auto/lrfg',
    COMMON: 'auto/common',
};
export const AEM_TAGS = {
    COMMON_TAG: 'auto/oem/fisker/common',
    HOMEPAGE_TAG: 'auto/oem/fisker/homepage',
    ERROR_PAGE_TAG: 'auto/oem/fisker/error-page',
    SITEMAPPAGE_TAG: 'auto/oem/fisker/sitemap',
    CONTACTUS_TAG: 'auto/oem/fisker/contact-us',
    FAQSPAGE_TAG: 'auto/oem/fisker/faqs',
    PREAPPROVALPAGE_TAG: 'auto/oem/lrfg/preapprovalpage',
    ACCOUNTS_TAG: 'auto/oem/fisker/myaccount',
};
export const AEM_IMAGES_PARAM = {
    SPLIT_WITH: '_Desktop1.jpg',
    JPG_EXT_PATH_DESKTOP: '_Desktop1.jpg',
    JPG_EXT_PATH_MOBILE: '_Mobile1.jpg',
    WEBP_EXT_PATH_DESKTOP: '_Desktop2.webp',
    WEBP_EXT_PATH_MOBILE: '_Mobile2.webp',
    CARD_SPLIT_PATH: '_1.jpg',
    CARD_SPLIT_PATH_WEBP: '_2.webp',
};
export const AEM_CONTENT_TRANSLATION_PARAM = {
    COMMON_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: { tag: AEM_TAGS.COMMON_TAG, version: 'latest' },
    },
    HOMEPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.HOMEPAGE_TAG,
            version: 'latest',
        },
    },
    ERROR_PAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.ERROR_PAGE_TAG,
            version: 'latest',
        },
    },
    SITEMAPPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.SITEMAPPAGE_TAG,
            version: 'latest',
        },
    },
    CONTACTUS_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.CONTACTUS_TAG,
            version: 'latest',
        },
    },
    FAQSPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.FAQSPAGE_TAG,
            version: 'latest',
        },
    },
    ACCOUNTS_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.ACCOUNTS_TAG,
            version: 'latest',
        },
    },
    PREAPPROVALPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.PREAPPROVALPAGE_TAG,
            version: 'latest',
        },
    },
};
export const NO_CONTENT_FOUND = 'No Content Found';
export const ARROW_WHITE_POINTING_RIGHT = `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_white_pointing_right.svg`;
export const GREEN_ARROW_ICON = `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_green_right.svg`;
export const OEM_BUTTON_TYPES = { PRIMARY: 'primary', SECONDARY: 'secondary' };
export const FAQ_SEARCH_RESULT_TEXT = {
    SHOW_WITH_COUNT: 'Showing counts result for "searchValue"',
    SHOW_WITHOUT_COUNT: 'No results found. Try a different search or',
};
export const ANALYTICS_CLASSNAME = {
    EXTERNAL_LINK: 'chaseanalytics-opt-exlnk',
    INTERNAL_LINK: 'chaseanalytics-track-link',
    INPUT_ELEMENT: 'chaseanalytics-track-element',
    SELECT_OPTION: 'chaseanalytics-track-option',
};
export const OEM_IMAGE_CATEGORY = {
    small: 'SM',
    large: 'LG',
    extraLarge: 'XL',
};
export const ADDITIONAL_HELP = 'Need additional help?';
export const ERROR_PAGE_OBJ = {
    imageLink: {
        desktopWebpUrl: `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/photography/partner/fisker/ErrorPage_HeroImage_Desktop2.webp`,
        imageWebpUrl: `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/photography/partner/fisker/ErrorPage_HeroImage_Mobile2.webp`,
        desktopJpegUrl: `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/photography/partner/fisker/ErrorPage_HeroImage_Desktop1.jpg`,
        imageJpegUrl: `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/photography/partner/fisker/ErrorPage_HeroImage_Mobile1.jpg`
    },
    heading: "IT LOOKS LIKE THIS PART OF OUR SITE ISN'T WORKING RIGHT NOW",
    description: [
        {
            text: 'Please try again later.',
        },
        {
            text: 'Thanks for your patience.',
        },
    ],
};
export const PROTECTED_URLS = ['/myaccount', '/myoffers', '/lease-end-turnin', '/lease-end-turnin-confirmation', '/logout'];
export const QA_AUTH_URL_DOMAIN = 'https://qac2-secure01ea.chase.com/';
export const PROD_AUTH_URL_DOMAIN = 'https://secure.chase.com/';
export const NAV_KEYS = "web/auth/?navKey=oemNavFisker&jp_cdf=FF_LOGIN&context=OEM_FISKER&treatment=FISKER&financialBrandName=Fisker+Finance&landingScreen=";
export const PROD_LOGIN_URL = `${PROD_AUTH_URL_DOMAIN}${NAV_KEYS}`;
export const QA_LOGIN_URL = `${QA_AUTH_URL_DOMAIN}${NAV_KEYS}`;
export const LOGOUT_PATH = '#/dashboard/signout/partner/OEM_FISKER'; // 'web/auth/router?context=LOGOFF'
export const PROD_LOGOUT_URL = `${PROD_AUTH_URL_DOMAIN}${LOGOUT_PATH}`;
export const QA_LOGOUT_URL = `${QA_AUTH_URL_DOMAIN}${LOGOUT_PATH}`;
export const EXTEND_SESSION_PATH = "web/auth/user/session/extend";
export const PROD_EXTEND_SESSION_URL = `${PROD_AUTH_URL_DOMAIN}${EXTEND_SESSION_PATH}`;
export const QA_EXTEND_SESSION_URL = `${QA_AUTH_URL_DOMAIN}${EXTEND_SESSION_PATH}`;
export const AEM_LOGOUT_PATH = '/logout';
export const PAYMENT_PATH = 'web/auth/dashboard?navKey=requestDashboard&jp_cdf=FF_MA_PMT';
export const CHASE_VISIT_PATH = 'web/auth/dashboard?navKey=requestOverviewDashboard&jp_cdf=FF_MA_VISIT';
export const AEM_PAYMENT_PATH = '/payment';
export const PAYMENT_PATH_LANDING_AFTER_AUTH = '/myaccount';
export const INACTIVE_TIMEOUT_DETAILS = {
    timeout: 1000 * 60 * 6, // 6 mins
    warningTimeout: 1000 * 60 // 1 min
};
export const PAGE_TITLES = {
    "/": 'Financing a Fisker - Fisker Financial Group',
    "/myaccount": 'Your Fisker Financial Group Account',
    "/sitemap": 'Sitemap',
    "/pay-manage": 'No Active Account',
    "/myoffers": 'You are pre-approved',
    "/error": 'Error page',
    "/oops": 'Oops, page not found',
    "/faq": 'FAQs - Fisker Financial Group',
    "/contact-us": 'Contact us - Fisker Financial Group'
};
export const ACCOUNT_STATUS = {
    ACTIVE: 'ACTIVE',
    EXTENDED: 'EXTENDED'
};
export const ACCOUNT_TYPE = {
    LOAN: 'LOAN',
    LEASE: 'LEASE'
};
export const DATE_ACTION = {
    ADD: 'ADD',
    SUBSTRACT: 'SUBSTRACT'
};
export const ALERT_TYPE = {
    ACTION: 'Action',
    INFO: 'Info',
    PROMO: 'Promo'
};
export const DOUBLE_OPEN_CURLY_BRACKETS = "{{";
export const DOUBLE_CLOSE_CURLY_BRACKETS = "}}";
export const SIGN_IN = 'Sign In';
export const SIGN_OUT = 'Sign Out';
export const PAY_AS_A_CUSTOMER = 'PayAsACustomer';
